// eyecatcher

section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		background: url("/images/Repeat Grid 8.svg");
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 1rem;
		z-index: 899;
	}
}
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large .owl-carousel .owl-caption-holder {
	* {
		z-index: 2;
	}
	&::before {
		content: "";
		background: rgba(#000, 0.25);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		z-index: 1;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	max-height: 1080px;

	height: 80vh;
	.owl-caption {
		display: flex;
		justify-content: center;
		.owl-subtitle,
		.owl-description,
		.owl-btn {
			display: none;
		}
		.owl-title {
			max-width: 75%;
			text-align: center;
			font-size: $h1-font-size;
		}
	}
	.owl-nav,
	.owl-dots {
		display: none;
	}
	@include media-breakpoint-down(lg) {
		height: 50vh;
		.owl-title {
			font-size: 36px !important;
		}
	}
}

section.eyecatcher.content {
	position: relative;
	&::before {
		content: "";
		background: url("/images/Repeat Grid 5.svg");
		position: absolute;
		top: -1px;
		left: 0;
		width: 100%;
		height: 1rem;
		z-index: 899;
		transform: scaleY(-1);
	}

	&::after {
		content: "";
		background: url("/images/Repeat Grid 5.svg");
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 1rem;
		z-index: 899;
	}
}
.eyecatcher.content,
.eyecatcher.content .item {
	height: 55vh;
	min-height: 540px;
	max-height: 1080px;
	padding: 0;
	display: flex;
	justify-content: center;
	.owl-dots {
		display: none;
	}
	.owl-caption-holder {
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		align-items: center !important;
		padding-bottom: 6vh;
		max-width: 750px;
		.owl-subtitle {
			display: none;
		}
		.owl-description {
			font-weight: bold;
			text-transform: uppercase;
			font-family: $font-family-primary;
		}
		@include media-breakpoint-down(lg) {
			.owl-title {
				font-size: 30px;
			}
			.owl-description {
				font-size: 14px;
			}
		}
	}

	.owl-nav {
		max-width: max-content;
		height: 100%;
		z-index: 899;
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		flex-flow: column;
		padding-left: 10vw;
		gap: 1rem;
		button {
			position: relative !important;
		}
		.owl-prev,
		.owl-next {
			position: static;
			transform: none;
			transform: scaleX(-1);

			i::before {
				font-family: $font-awesome;
				color: $white;
				font-weight: 400;
				font-size: 36px;
			}

			&:hover {
				i::before {
					color: rgba($white, 0.8);
				}
			}
		}

		.owl-prev i::before {
			content: "\f359";
		}

		.owl-next i::before {
			content: "\f35a";
		}
		@include media-breakpoint-down(lg) {
			padding-left: 15px;
			max-height: 50vh;
			.owl-nav {
				gap: 15px;
			}
			.owl-prev,
			.owl-next {
				max-height: 24px;
				i::before {
					font-size: 24px;
				}
			}
		}
	}
	@include media-breakpoint-up(sm) {
		height: 75vh !important;
	}
}
