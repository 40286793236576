// footer
.footer {
	section.logo {
		background: $white;
		padding: 8vh 15px;
		position: relative;
		.logo {
			display: flex !important;
			justify-content: center;
			@include media-breakpoint-down(lg) {
				img {
					max-width: 265px;
				}
			}
		}
		&::before {
			content: "";
			background: url("/images/Repeat Grid 5.svg");
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			height: 1rem;
			z-index: 899;
			transform: scaleY(-1);
		}
	}
	section.links {
		background: $dark;
		padding: 12vh 15px 6vh 15px;
		position: relative;
		.column {
			* {
				color: $white;
			}
		}
		.footer-text {
			h5 {
				margin-bottom: 1rem;
			}
		}
		.footer-links {
			ul.list {
				display: flex;
				flex-flow: column;
				gap: 0.5rem;
				li {
					a {
						font-size: 14px;
					}
				}
			}
		}
		ul.footer-contact-buttons {
			gap: 0.5rem;
			li {
				width: 90%;
			}
			a {
				@extend .btn;
				background: $white;
				min-width: 100% !important;
				display: flex;
				align-items: center;
				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
				i {
					margin-right: 0.5rem;
					transition: 0.35s ease;
					&::before {
						color: $dark;
						font-weight: 400;
					}
				}
				span {
					color: $dark !important;
					font-weight: 400;
				}
			}
		}
		ul.social-icons {
			margin-top: 1.5rem;
			display: flex;
			gap: 1rem;
			li {
				i {
					font-size: 24px;
					color: $white;
					transition: 0.35s ease;
				}
				span {
					display: none;
				}
				&:hover {
					i {
						transform: scaleX(-1);
						transition: 0.35s ease;
					}
				}
			}
		}
		&::before {
			content: "";
			background: url("/images/Repeat Grid footer.svg");
			position: absolute;
			top: -1rem;
			left: 0;
			width: 100%;
			height: 1rem;
			z-index: 899;
		}
		@include media-breakpoint-down(lg) {
			padding-top: 3vh !important;
		}
	}
	section.footer-socket {
		background: $dark;
		padding: 1vh 0;
		.list {
			display: flex;
			flex-flow: row;
			align-items: center;
			justify-content: center;
			color: $white;
			gap: 0.75rem;
			a {
				color: $white;
				font-size: 12px;
				text-decoration: none;
			}
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				gap: 0.35rem;
				a {
					font-size: 12px;
					font-weight: 200;
				}
			}
		}
	}
	section.partners {
		padding: 6vh 15px;
		background: $dark;
		ul.partner-logos {
			display: flex;
			gap: 1.5rem;
			justify-content: center;
			align-items: center;
			.list-item-title {
				display: none;
			}
			li a {
				img {
					transition: 0.35s ease;
				}
				&:hover {
					img {
						filter: brightness(0.75);
						transition: 0.35s ease;
					}
				}
			}
		}
	}
}
