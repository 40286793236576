// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// intro section
.intro-section {
	padding: 6vh 0;
	padding-right: 0;
	background: $green;
	overflow: hidden;
	position: relative;
	.intro {
		max-width: 750px;
		margin-bottom: 3vh;
		.container-holder {
			text-align: center;

			margin-bottom: 30px;
			.title * {
				color: $white;
			}
			p {
				color: $white;
			}
		}
	}
	.tree-image {
		opacity: 0.35;
		margin: 1rem;
	}
	&::after {
		content: "";
		background: url("/images/Repeat Grid 4.svg");
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: 1rem;
		z-index: 998;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			margin: 0 auto;
			text-align: left;
			.column {
				padding: 0;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-start;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// omgeving section
.omgeving-section {
	padding: 6vh 0;

	.intro {
		display: flex;
		justify-content: center;
		margin: 6vh auto;
		.container-holder {
			max-width: 750px;
			text-align: center;
		}
	}
	@include media-breakpoint-down(lg) {
		padding: 3vh 0;
		.intro {
			.container-holder {
				max-width: 90%;
			}
			.title * {
				font-size: 30px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	&.accommodaties {
		.container {
			.container-holder {
				padding: 1.5rem 0;
				.column {
					padding-right: 0;
					padding-left: 0;
				}
				@include media-breakpoint-down(lg) {
					padding: 15px;
				}
			}
		}
		.container.usps {
			margin-top: 9vh;
			display: flex;
			justify-content: center;
			.container-holder .column {
				display: flex;
				justify-content: center;
				width: 100%;
				padding: 15px;
			}
			ul.home-usp {
				display: flex;
				flex-flow: column;
				gap: 3rem;
				max-width: 570px;
				li {
					display: flex;
					align-items: flex-start;
					gap: 0.75rem;
					.caption {
						display: flex;
						flex-flow: column;
						.list-item-title {
							color: $dark;
							text-transform: uppercase;
							font-weight: bold;
						}
					}
					i {
						min-width: 40px;
						&::before {
							font-size: 36px;
							font-weight: 400;
						}
					}
				}
			}
			@include media-breakpoint-down(lg) {
				margin-top: 3vh;
				li {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-8vh + 30px);
	margin-bottom: calc(-8vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
