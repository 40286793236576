// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$green: #6B901A;
$green-dark: #54842F;
$green-darker:#4B721E;

$dark: #3E433A;
$darker: #0C1D2A;

$grey:#EFEEE3;
$grey-dark:#707070;

$white: #fff;
$black: #000;

// theme-colors
$primary: $dark;
$secondary: $green-dark;

// body
$body-bg: $grey;
$body-color: $dark;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;600;700&display=swap');
$font-family-primary: 'Inter', sans-serif;
$font-family-secondary: 'Ibarra Real Nova', sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 3.75; // 60px
$h2-font-size: $font-size-base * 3.125; // 50px
$h3-font-size: $font-size-base * 2.25; // 36px
$h4-font-size: $font-size-base * 1.875; // 30px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $dark;

// contextual
$light: $green;
$dark: $dark;
$text-light: $white;
$text-dark: $black;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
