// btn
.btn {
	box-shadow: $shadow;
	font-weight: 400;
	text-transform: uppercase;
	padding-left: 2rem;
	padding-right: 2rem;
	// btn-primary
	&.btn-primary {
		&:hover {
			background: $darker;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: flex-start;
	padding-left: 1rem;
	font-weight: 600;
	text-decoration: none;
	&:hover {
		&::before {
			transform: translateX(-5px);
		}
	}
	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
