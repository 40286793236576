// mini-sab
.mini-sab {
	background: $green;
	position: relative;
	.container {
		position: relative;
		z-index: 991;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin-top: -35px;
		}
	}
	&::after {
		content: "";
		background: url("/images/Repeat Grid 8.svg");
		position: absolute;
		bottom: calc(-1rem +1px);
		left: 0;
		width: 100%;
		height: 1rem;
		z-index: 899;
		transform: scale(-1, -1);
	}
}
