// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.card,
.default-card,
.card.default,
.card.icon {
	border-radius: 8px;
	border: none;
	box-shadow: $shadow;
	min-height: 500px;
	.card-subtitle {
		display: none !important;
	}
	.card-image {
		&::after {
			content: "";
			background: url("/images/card repeat.svg");
			background-repeat: repeat-x;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1.2rem;
		}
	}
	.card-body {
		.card-caption {
			text-align: center;
			padding: 4vh 0;
		}
		.card-title {
			color: $dark;
			font-size: 30px;
		}
		.card-description {
			font-size: 16px;
			color: $dark;
			display: flex;
			flex-flow: column;
		}
	}
	ul.list {
		order: -1;
		display: flex;
		justify-content: center;
		gap: 0.5rem;
		margin-bottom: 1rem;
		li {
			display: flex;
			width: max-content;
		}
	}
}

.card.card-assortiment .card-image-caption {
	top: 0;
	bottom: unset;
}

.page-block.form {
	max-width: 1110px;
	margin: 0 auto;
}
