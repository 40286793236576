.faciliteiten-slider {
	padding: 1rem;
	padding-left: 10vw;
	padding-right: 0;
	display: flex !important;
	flex-flow: row-reverse nowrap !important;
	.owl-stage-outer {
		padding-bottom: 6vh;
		display: flex;
	}
	.owl-nav {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		margin-right: 1rem;
		gap: 0.45rem;
		max-width: max-content;

		.owl-prev,
		.owl-next {
			position: static;
			transform: none;
			transform: scaleX(-1);
			i::before {
				font-family: $font-awesome;
				color: $white;
				font-weight: 400;
				font-size: 36px;
			}

			&:hover {
				i::before {
					color: rgba($white, 0.8);
				}
			}
			@include media-breakpoint-down(lg) {
				max-width: 30px;
				max-height: 24px;
				i::before {
					font-size: 24px !important;
				}
			}
		}

		.owl-prev i::before {
			content: "\f359";
		}
		.owl-next i::before {
			content: "\f35a";
		}
		@include media-breakpoint-down(lg) {
			gap: 10px !important;
		}
	}
	.item .card {
		border-radius: 8px;
		border: none;
		box-shadow: $shadow;
		min-height: unset !important;
		.card-image {
			&::after {
				content: "";
				background: linear-gradient(rgba(#000, 0), rgba(#000, 0.45));
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 45%;
				z-index: 0;
			}
		}
		.card-image,
		.card-body {
			position: absolute;
			min-height: 100%;
			min-width: 100%;
			.card-caption {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				padding-bottom: 0;
				.card-title {
					font-size: 24px;
					font-weight: 600;
					color: $white;
				}
				.card-subtitle,
				.card-description {
					display: none;
				}
			}
		}
	}
	.owl-item {
		opacity: 0;
		transition-delay: 0.5s;
		transition: 0.35s;
	}
	.owl-item.active {
		opacity: 1;
		transition: 0.5s ease-in;
		&:hover {
			transform: scale(1.025);
			z-index: 3;
		}
	}
	.owl-item:nth-child(even) {
		transform: translateY(10%);
		&:hover {
			transform: scale(1.025) translateY(10%);
			z-index: 3;
		}
	}
	@include media-breakpoint-down(lg) {
		padding-left: 5px;
	}
}

// accommodatie slider

// .accommodaties-slider {
// 	.owl-stage-outer {
// 		padding: 6vh 1rem 6vh 1rem;
// 	}
// 	.owl-nav,
// 	.owl-dots {
// 		display: none;
// 	}
// 	.owl-item:nth-child(odd) {
// 		transform: translateY(6vh);
// 		transition: 0.35s ease;
// 		&:hover {
// 			transform: translateY(3vh) scale(1.01);
// 			transition: 0.35s ease;
// 			z-index: 3;
// 		}
// 	}
// 	.owl-item:nth-child(even) {
// 		transition: 0.35s ease;
// 		&:hover {
// 			transform: scale(1.01);
// 			transition: 0.35s ease;
// 			z-index: 3;
// 		}
// 	}
// 	.owl-item:nth-child(1n + 0) {
// 		z-index: 3;
// 	}
// 	.owl-item:nth-child(2n + 0) {
// 		z-index: 2;
// 	}
// 	.owl-item:nth-child(3n + 0) {
// 		z-index: 1;
// 	}

// 	.item .card {
// 		border-radius: 8px;
// 		border: none;
// 		box-shadow: $shadow;
// 		.card-image {
// 			&::after {
// 				content: "";
// 				background: linear-gradient(rgba(#000, 0), rgba(#000, 0.45));
// 				position: absolute;
// 				bottom: 0;
// 				left: 0;
// 				width: 100%;
// 				height: 45%;
// 				z-index: 0;
// 			}
// 		}

// 		.card-image,
// 		.card-body {
// 			position: absolute;
// 			min-height: 100%;
// 			min-width: 100%;

// 			.card-caption {
// 				display: flex;
// 				align-items: center;
// 				justify-content: flex-end;
// 				flex-flow: column;

// 				.card-title {
// 					font-size: 36px;
// 					font-weight: 600;
// 					color: $white;
// 				}
// 				.card-subtitle {
// 					color: $white;
// 					font-size: 14px;
// 					text-transform: uppercase;
// 					font-weight: 400;
// 					font-family: $font-family-primary !important;
// 				}

// 				.card-description {
// 					display: none;
// 				}
// 			}
// 		}
// 	}
// }
.accommodaties-slider.owl-carousel {
	display: none !important;
}
.accommodaties-slider {
	.grid-items {
		padding: 0;
		justify-content: center !important;
		gap: 0;
		flex-flow: row nowrap !important;

		padding-left: 3%;
		.item {
			margin: 0 !important;
			padding: 0 !important;
			flex-basis: 35% !important;
			max-width: 35% !important;
			margin-left: -3% !important;
			.card {
				width: 100%;
				margin: 0 !important;
				.card-image::after {
					display: none;
				}
			}
		}
		.item:nth-child(odd) {
			transform: translateY(6vh);
			transition: 0.35s ease;
			&:hover {
				transform: translateY(3vh) scale(1.01);
				transition: 0.35s ease;
				z-index: 3;
			}
		}
		.item:nth-child(even) {
			transition: 0.35s ease;
			&:hover {
				transform: scale(1.01);
				transition: 0.35s ease;
				z-index: 3;
			}
		}
		.item:nth-child(1n + 0) {
			z-index: 3;
		}
		.item:nth-child(2n + 0) {
			z-index: 2;
		}
		.item:nth-child(3n + 0) {
			z-index: 1;
		}
		@include media-breakpoint-down(md) {
			flex-flow: column !important;
			padding-left: 0!important;
			.item {
				padding: 15px !important;
				flex-basis: 100% !important;
				max-width: 100% !important;
				margin-left: unset !important;
				transform: none !important;
				.card {
					min-height: unset !important;
					.card-caption {
						height: 90%;
						text-align: center;
					}
				}
			}
		}
	}

	.card-caption {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-flow: column;
		height: 100%;

		.card-title {
			font-size: 36px;
			font-weight: 600;
			color: $white;
		}
		.card-subtitle {
			display: block !important;
			color: $white;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 400;
			font-family: $font-family-primary !important;
		}

		.card-description {
			display: none;
		}
	}
}
