// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;
	@include media-breakpoint-down(lg) {
		background: $green;
	}
	&.sticky {
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding-left: 0;
					padding-right: 0;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 997;
		.navbar-toggler {
			background: $green-darker;
			color: $white;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			padding: 5px 15px;
			min-width: 115px;
			span {
				font-size: 16px;
			}
			i {
				font-family: $font-awesome;
				font-size: 24px;
				&::before {
					content: "\f036";
					font-weight: 400;
					font-style: normal;
				}
			}
			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 330px;
		margin: 10px 10px 10px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			order: 1;
			max-width: 100%;
			flex-basis: 100%;
			background: $white;
			margin: 0;
			display: flex;
			justify-content: center;
			padding: 1rem;
			max-height: 200px;
			img {
				max-width: 250px;
				transition: 0.35s ease;
			}
			&.hide {
				padding: 2px;
				transition: 0.35s ease;
				img {
					max-width: 100px;
					transition: 0.35s ease;
				}
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 2;
			background: rgba($green-dark, 0.8);
			position: fixed;
			left: -100vw;
			top: 0;
			width: 100vw;
			height: 100vh !important;
			transition: 0.45s ease;
			z-index: 996;
			backdrop-filter: blur(4px);
			display: flex;

			padding: 1.3rem;
			&.show {
				left: 0;
				transition: 0.45s ease;
			}
			.navbar-nav {
				width: 100%;
				.nav-item {
					width: 100%;
					.nav-link {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						color: $white;
						font-size: $h5-font-size;
						font-weight: 600;
					}
					.dropdown-menu.show {
						transform: none !important;
						position: static !important;
						background: none !important;
						border: none !important;
						.nav-link {
							font-weight: 300;
							margin-left: 1rem;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			ul.navbar-nav {
				margin-left: 20%;
				gap: 1.25rem;
				.nav-item {
					.nav-link {
						font-weight: 600;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $green;
						}
					}
				}
			}
			.dropdown-menu.show {
				background: $white;
				box-shadow: none;
				border: 0;
				padding: 1rem;
				min-width: 120%;
				.nav-item {
					display: flex;
					justify-content: center;
					text-align: center;
				}
				&::before,
				&::after {
					all: unset;
				}
				&::after {
					content: "";
					bottom: -0.99rem;
					left: 0;
					position: absolute;
					width: 100%;
					height: 1rem;
					background: url("/images/Repeat Grid 2.svg");
					background-repeat: repeat-x;
					transform: scaleY(-1);
				}
			}
		}
	}
	//  header button
	.header-btn {
		margin-right: 5px;
		a {
			@extend .btn, .btn-primary;
			font-size: 14px;
		}
		@include media-breakpoint-down(lg) {
			margin-left: auto;
			z-index: 999;
			a {
				background: $green !important;
				border: none;
				padding-left: 0.5rem !important;
				padding-right: 0rem !important;
				box-shadow: none !important;
			}
		}
	}
	// site-switcher
	.site-switcher {
		@include media-breakpoint-down(lg) {
			padding-right: 15px;
			z-index: 999;
		}
	}

	&::after {
		content: "";
		height: 1rem;
		width: 100%;
		bottom: calc(-1rem + 1px);
		left: 0;
		background: url("/images/Repeat Grid 2.svg");
		background-repeat: repeat-x;
		z-index: 899;
		position: absolute;
		transform: scaleY(-1);
	}
}
